input,
select,
textarea {
  @apply shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:ring-pink-500 transform transition hover:scale-105 duration-300 ease-in-out;
}

label {
  @apply block text-blue-300 py-2 font-bold mb-2;
}

button {
  @apply bg-gradient-to-r from-purple-800 to-green-500 hover:from-pink-500 hover:to-green-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out;
}

.form {
  @apply bg-gray-900 opacity-75 w-full shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4;
}
